import React, { useState } from "react";
import { navigate } from "gatsby";

import { base, sheet } from "./DropdownLink.module.scss";

import Link from "./Link";
import { useClickOutside } from "../utils/hooks";
import { combine } from "../utils/classNames";

const DropdownLink = props => {
  const { children, items, className } = props;

  const [isVisible, setVisible] = useState(false);

  useClickOutside(() => {
    setVisible(false)
  }, isVisible);

  const handleClick = () => {
    setVisible(!isVisible);
  };

  return (
    <div className={combine(base, className, isVisible ? "--is-visible" : "")}>
      <Link onClick={handleClick}>
        {children}
        <i className="material-icons">expand_more</i>
      </Link>
      {isVisible && (
        <div className={sheet}>
          {items.map((item, index) => (
            <Link key={index} href={item.href}>
              {item.label}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownLink;
