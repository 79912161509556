import React from "react";

import { header, headerLeft, headerRight, headerLink, nav } from "./Header.module.scss";

import Link from "./Link";
import DropdownLink from "./DropdownLink";
import Button from "./Button";

import { combine, getClassNameModifiers } from "../utils/classNames";

const Header = props => {
  const { className } = props;

  const modifiers = getClassNameModifiers(props, ["isFixed"]);

  return (
    <header className={combine(header, modifiers, className)}>
      <div className={headerLeft}>
        <Link href="/" className={headerLink}>
          <img src="/images/brandcrush-logo.svg" alt="Brandcrush logo" />
        </Link>
        <nav className={nav}>
          <ul>
            <li>
              <DropdownLink
                className={headerLink}
                items={[
                  { label: "Solutions", href: "/partners" },
                  { label: "Retail & grocery", href: "/brandcrush-for-business/retail-grocery" },
                  {
                    label: "D2C retail & food delivery",
                    href: "/brandcrush-for-business/d2c-retail-food-delivery",
                  },
                  {
                    label: "Shopping centres/malls",
                    href: "/brandcrush-for-business/shopping-centres-malls",
                  },
                  { label: "Health & wellness", href: "/brandcrush-for-business/health-wellness" },
                  {
                    label: "Corporate & coworking",
                    href: "/brandcrush-for-business/corporate-coworking",
                  },
                  { label: "Universities", href: "/brandcrush-for-business/universities" },
                  {
                    label: "Hospitality & tourism",
                    href: "/brandcrush-for-business/hospitality-tourism",
                  },
                ]}
              >
                Media owners
              </DropdownLink>
              <DropdownLink
                className={headerLink}
                items={[
                  { label: "Brands", href: "/brands" },
                  { label: "Agencies", href: "/agencies" },
                ]}
              >
                Media buyers
              </DropdownLink>
            </li>
            <li>
              <Link href="/pricing" className={headerLink}>
                Pricing
              </Link>
            </li>
            <li>
              <Link href="/about" className={headerLink}>
                About us
              </Link>
            </li>
          </ul>
        </nav>
      </div>
      <div className={headerRight}>
        <Link href="/app/login" target="_self" className={headerLink}>
          Log in
        </Link>
        <Button size="md" href="/book-a-demo">
          Book a call
        </Button>
      </div>
    </header>
  );
};

export default Header;
