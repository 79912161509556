import React, { useState, useEffect } from "react";

import { base, sheet } from "./Select.module.scss";

import { combine } from "../utils/classNames";

const Select = props => {
  const { className, name, options, initialValue, onChange } = props;

  const [value, setValue] = useState(initialValue);
  const [isExpanded, setExpanded] = useState(false);

  const handleOpenClick = () => {
    setExpanded(!isExpanded);
  };

  const handleOptionClick = value => {
    setValue(value);

    if (onChange && typeof onChange === "function") {
      onChange(value);
    }
  };

  useEffect(() => {
    if (isExpanded && typeof document !== "undefined") {
      document.documentElement.onclick = () => {
        setExpanded(false);
        document.documentElement.onclick = null;
      };
    }
  });

  return (
    <div
      className={combine(base, isExpanded ? "--is-expanded" : "", className)}
      onClick={handleOpenClick}
    >
      <div>{options.find(option => option.value === value).label}</div>
      <i className="material-icons">expand_more</i>
      {isExpanded && (
        <ul className={sheet}>
          {options.map((option, index) => (
            <li key={index} onClick={() => handleOptionClick(option.value)}>
              {option.label}
            </li>
          ))}
        </ul>
      )}
      <input type="hidden" value={value} name={name}></input>
    </div>
  );
};

export default Select;
