import React from "react";

import {
  footer,
  linksContainer,
  logoContainer,
  copyright,
  countryContainer,
} from "./Footer.module.scss";

import Link from "./Link";
import LinksColumn from "../partials/LinksColumn";
import SubscribeForm from "../partials/SubscribeForm";

const Footer = props => {
  return (
    <footer className={footer}>
      <SubscribeForm />
      <hr />
      <div className={linksContainer}>
        <LinksColumn
          heading="How it works"
          items={[
            { label: "Media owners", href: "/partners" },
            { label: "Brands", href: "/brands" },
            { label: "Agencies", href: "/agencies" },
            { label: "Marketplace", href: "/app/login" },
            { label: "Pricing", href: "/pricing" },
            { label: "FAQs", href: "/FAQs" },
            { label: "Support", href: "/support" },
          ]}
        />
        <LinksColumn
          heading="Discover"
          items={[
            { label: "About Brandcrush", href: "/about" },
            { label: "Case studies", href: "/case-studies" },
            { label: "Our blog", href: "/blog" },
            { label: "Contact us", href: "/contact" },
          ]}
        />
        <LinksColumn
          heading="Brandcrush for"
          items={[
            { label: "Your business", href: "/brandcrush-for-business" },
            { label: "Retail & grocery", href: "/brandcrush-for-business/retail-grocery" },
            {
              label: "D2C retail & food delivery",
              href: "/brandcrush-for-business/d2c-retail-food-delivery",
            },
            {
              label: "Shopping centres/malls",
              href: "/brandcrush-for-business/shopping-centres-malls",
            },
            { label: "Health & wellness", href: "/brandcrush-for-business/health-wellness" },
            {
              label: "Corporate & coworking",
              href: "/brandcrush-for-business/corporate-coworking",
            },
            { label: "Universities", href: "/brandcrush-for-business/universities" },
            {
              label: "Hospitality & tourism",
              href: "/brandcrush-for-business/hospitality-tourism",
            },
          ]}
        />
        <div>
          <LinksColumn
            heading="Terms and privacy"
            items={[
              { label: "Terms", href: "/termsofservice" },
              { label: "Privacy", href: "/privacypolicy" },
            ]}
          />
          {/* <LinksColumn
            heading="Subscription Agreements"
            items={[
              { label: "Lite", href: "/" },
              { label: "Pro", href: "/" },
            ]}
          />
          <LinksColumn
            heading="LEGAL"
            items={[
              { label: "Privacy Policy", href: "/privacypolicy" },
              { label: "Legal FAQs", href: "/" },
            ]}
          /> */}
        </div>
      </div>
      <div className={copyright}>© Brandcrush Pty Ltd 2022</div>
      <hr />
      <div className={countryContainer}>
        <div className={logoContainer}>
          <Link href="https://www.facebook.com/brandcrushplatform/" external>
            <img src="/images/facebook-logo.svg" alt="Facebook logo" />
          </Link>
          <Link href="https://www.instagram.com/brandcrush.platform/" external>
            <img src="/images/instagram-logo.svg" alt="Instagram logo" />
          </Link>
          <Link href="https://www.linkedin.com/company/brandcrush/" external>
            <img src="/images/linkedin-logo.svg" alt="Linkedin logo" />
          </Link>
        </div>
        <small className="desktop-only">
          United States&nbsp;|&nbsp;Australia&nbsp;|&nbsp;United Kingdom&nbsp;|&nbsp;New Zealand
        </small>
        <small className="mobile-only">
          <div>United States&nbsp;|&nbsp;Australia</div>
          <div>United Kingdom&nbsp;|&nbsp;New Zealand</div>
        </small>
      </div>
    </footer>
  );
};

export default Footer;
