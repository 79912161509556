import { useEffect, useState, useRef } from "react";

export const useInView = ref => {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  });

  return isIntersecting;
};

export const useMediaQuery = query => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);

    if (media.matches !== matches) {
      setMatches(media.matches);
    }

    const listener = () => {
      setMatches(media.matches);
    };

    media.addListener(listener);

    return () => media.removeListener(listener);
  }, [matches, query]);

  return matches;
};

export const usePagination = (limit, makeRequest) => {
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState(null);

  useEffect(() => {
    makeRequest(limit, offset).then(response => setData(response.data));
  });

  const page = offset / limit + 1;
  const setPage = page => setOffset((page - 1) * limit);
  const incrementPage = () => setOffset(offset + limit);

  return [data, page, setPage, incrementPage];
};

export const useClientPagination = (limit, data) => {
  const [numberResults, setNumberResults] = useState(limit);

  const filteredData = data && data.filter(x => x);

  const results = filteredData && filteredData.slice(0, numberResults);

  const showMore = () => setNumberResults(numberResults + limit);
  const shouldShowMore = results.length < filteredData.length && filteredData.length > limit;

  return [results, showMore, shouldShowMore];
};

export const useRegexSearch = (data, keys) => {
  const getRecursiveChildText = reactNode => {
    if (Array.isArray(reactNode)) {
      // Multiple children
      let joinedNodes = [];
      reactNode.forEach(node => {
        if (typeof node === "object") joinedNodes.push(getRecursiveChildText(node));
        else if (typeof node === "string") joinedNodes.push(node);
      });
      return joinedNodes.join(" ");
    }

    const children = reactNode.props.children || undefined;

    if (children === undefined) {
      if (typeof reactNode === "string") return reactNode;
      else return " ";
    }
    if (typeof children === "object") {
      // Found direct child
      return getRecursiveChildText(reactNode.props.children);
    }
    if (typeof children === "string") {
      // Found searchable string
      return reactNode.props.children;
    }
  };

  const [query, setQuery] = useState("");

  const re = query ? new RegExp(query.replace(/[.*+\-?^${}()|[\]\\]/g, "\\$&"), "i") : null;

  const filteredData =
    data &&
    data.filter(obj =>
      re === null
        ? true
        : keys.some(key => {
            let string = "";
            if (typeof obj[key] === "string") {
              string = obj[key];
            } else {
              string = getRecursiveChildText(obj[key]);
            }
            return string.search(re) !== -1;
          })
    );

  const isActive = query !== "";

  return [setQuery, filteredData, isActive];
};

export const useClientFilter = (data, key) => {
  const [filter, setFilter] = useState("");

  const filteredData = data && data.filter(obj => (filter === "" ? true : obj[key] === filter));

  return [filter, setFilter, filteredData];
};

export const useDebounce = (func, time = 400) => {
  let timeout;

  return function () {
    const functionCall = () => func.apply(this, arguments);

    clearTimeout(timeout);
    timeout = setTimeout(functionCall, time);
  };
};

export const useGesture = (onTouchLeft, onTouchRight) => {
  const ref = useRef(null);

  let touchStartX = 0;
  let touchEndX = 0;

  const handleGesture = () => {
    if (touchEndX < touchStartX) onTouchLeft();
    if (touchEndX > touchStartX) onTouchRight();
  };

  useEffect(() => {
    ref.current.addEventListener("touchstart", e => {
      touchStartX = e.changedTouches[0].screenX;
    });

    ref.current.addEventListener("touchend", e => {
      touchEndX = e.changedTouches[0].screenX;
      handleGesture();
    });
  });

  return ref;
};

export const useFixedHeight = condition => {
  useEffect(() => {
    if (typeof document !== "undefined") {
      if (condition) {
        document.documentElement.style.setProperty("overflow-y", "hidden");
      } else {
        document.documentElement.style.setProperty("overflow-y", "scroll");
      }
    }
  }, [condition]);
};

export const useIOSViewHeight = () => {
  // window.innerHeight gives the correct viewport height in iOS Safari, taking into account the
  // bottom navbar. But it's only available in javascript, so we need to set a css property to
  // access in css.
  //
  // Usage:
  //
  // height: calc(var(--vh, 1vh) * 100);

  useEffect(() => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }
  });
};

export const useClickOutside = (func, condition) => {
  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }

    if (condition) {
      window.onclick = func;
    } else {
      window.onclick = null;
    }
  }, [condition]);
};
