import React, { useState } from "react";

import { base, button, inputIcon, errorLabel } from "./Input.module.scss";

import Button from "./Button";

import { combine } from "../utils/classNames";

const Input = props => {
  const {
    className,
    name,
    placeholder,
    hasSubmitButton,
    buttonLabel,
    onSubmit,
    onSubmitSuccess,
    onSubmitFail,
    onChange,
    icon,
    iconAlt,
    required,
    clearOnSubmit,
  } = props;

  const [value, setValue] = useState("");
  const [error, setError] = useState(false);

  const handleChange = evt => {
    setValue(evt.target.value);

    if (onChange && typeof onChange === "function") {
      onChange(evt, evt.target.value);
    }
  };

  const handleClick = evt => {
    evt.preventDefault();

    if (required && !value) {
      setError(true);
      return;
    }

    if (onSubmit && typeof onSubmit === "function") {
      onSubmit(evt, value);

      if (clearOnSubmit) {
        setValue("");
      }
    }
  };

  return (
    <div className={combine(base, error ? "--is-invalid" : "", className)}>
      <input name={name} placeholder={placeholder} value={value} onChange={handleChange}></input>
      {error && <div className={errorLabel}>*Required</div>}
      {hasSubmitButton && (
        <Button className={button} color="navy" onClick={handleClick}>
          {buttonLabel}
        </Button>
      )}
      {icon && <img className={inputIcon} src={icon} alt={iconAlt} onClick={handleClick} />}
    </div>
  );
};

export default Input;
