import React from "react";

import { base } from "./LinksColumn.module.scss";

const LinksColumn = props => {
  const { items, heading } = props;

  return (
    <div className={base}>
      <h4>{heading}</h4>
      <ul>
        {items.map((item, index) => (
          <li key={index}>
            <a href={item.href}>{item.label}</a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LinksColumn;
