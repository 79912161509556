import React, { useState } from "react";

import {
  header,
  logo,
  hamburger,
  close,
  mobileMenu,
  mobileMenuHeader,
  mobileMenuBody,
  demoButton,
  loginButton,
} from "./MobileHeader.module.scss";

import Link from "./Link";
import Button from "./Button";

import { combine, getClassNameModifiers } from "../utils/classNames";
import { useFixedHeight, useIOSViewHeight } from "../utils/hooks";

const Hamburger = props => {
  const { onClick } = props;

  return (
    <div className={hamburger} onClick={onClick}>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

const MobileMenu = props => {
  const { handleClose } = props;

  const [showBrandsSubMenu, setShowBrandsSubMenu] = useState(false);
  const [showPartnersSubMenu, setShowPartnersSubMenu] = useState(false);

  const modifiers = getClassNameModifiers(props, ["isOpen"]);

  const renderContent = () => {
    if (showBrandsSubMenu) {
      return (
        <ul>
          <li>
            <Link onClick={() => setShowBrandsSubMenu(false)}>
              <i className="material-icons">west</i>&nbsp;Back
            </Link>
          </li>
          <li>
            <Link href="/brands">Brands</Link>
          </li>
          <li>
            <Link href="/agencies">Agencies</Link>
          </li>
        </ul>
      );
    } else if (showPartnersSubMenu) {
      return (
        <ul>
          <li>
            <Link onClick={() => setShowPartnersSubMenu(false)}>
              <i className="material-icons">west</i>&nbsp;Back
            </Link>
          </li>
          <li>
            <Link href="/partners">Solutions</Link>
          </li>
          <li>
            <Link href="/brandcrush-for-business/retail-grocery">Retail &amp; grocery</Link>
          </li>
          <li>
            <Link href="/brandcrush-for-business/d2c-retail-food-delivery">
              D2C retail &amp; food delivery
            </Link>
          </li>
          <li>
            <Link href="/brandcrush-for-business/shopping-centres-malls">
              Shopping centres/malls
            </Link>
          </li>
          <li>
            <Link href="/brandcrush-for-business/health-wellness">Health &amp; wellness</Link>
          </li>
          <li>
            <Link href="/brandcrush-for-business/corporate-coworking">
              Corporate &amp; coworking
            </Link>
          </li>
          <li>
            <Link href="/brandcrush-for-business/universities">Universities</Link>
          </li>
          <li>
            <Link href="/brandcrush-for-business/hospitality-tourism">
              Hospitality &amp; tourism
            </Link>
          </li>
        </ul>
      );
    } else {
      return (
        <ul>
          <li>
            <Link onClick={() => setShowPartnersSubMenu(true)}>
              Media owners<i className="material-icons">navigate_next</i>
            </Link>
          </li>
          <li>
            <Link onClick={() => setShowBrandsSubMenu(true)}>
              Media buyers<i className="material-icons">navigate_next</i>
            </Link>
          </li>
          <li>
            <Link href="/pricing">Pricing</Link>
          </li>
          <li>
            <Link href="/about">About us</Link>
          </li>
        </ul>
      );
    }
  };

  return (
    <div className={combine(mobileMenu, modifiers)}>
      <div className={mobileMenuHeader}>
        <img src="/images/brandcrush-logo-no-text.svg" alt="Brandcrush logo" className={logo} />
        <img src="/images/close-icon.svg" alt="close" className={close} onClick={handleClose} />
      </div>
      <div className={mobileMenuBody}>
        <nav>{renderContent()}</nav>
        <div>
          <Button className={demoButton} size="md" href="/book-a-demo">
            Book a call
          </Button>
          <Button
            className={loginButton}
            isSecondary
            color="navy"
            size="md"
            target="_self"
            href="/app/login"
          >
            Log in
          </Button>
        </div>
      </div>
    </div>
  );
};

const MobileHeader = props => {
  const { className } = props;

  const [isOpen, setIsOpen] = useState(false);

  useFixedHeight(isOpen);
  useIOSViewHeight();

  const handleOpenClick = () => {
    setIsOpen(true);
  };

  const handleCloseClick = () => {
    setIsOpen(false);
  };

  return (
    <header className={combine(header, className)}>
      <Link href="/">
        <img className={logo} src="/images/brandcrush-logo.svg" alt="Brandcrush logo" />
      </Link>
      <Hamburger onClick={handleOpenClick} />
      <MobileMenu isOpen={isOpen} handleClose={handleCloseClick} />
    </header>
  );
};

export default MobileHeader;
