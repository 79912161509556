import React from "react";
import { navigate } from "gatsby";

import { base } from "./Button.module.scss";

import { getClassNameModifiers, combine } from "../utils/classNames";

const Button = props => {
  const { children, className, type, onClick, disabled, href, target } = props;

  const modifiers = getClassNameModifiers(props, ["isSecondary", "isLink", "color", "size", "isBlock"]);

  const handleClick = evt => {
    if (href) {
      if (target === "_blank" && typeof window !== "undefined") {
        window.open(href, "_blank");
        return;
      }

      navigate(href);
      return;
    }

    if (onClick && typeof onClick === "function") {
      onClick(evt);
      return;
    }
  };

  return (
    <button
      className={combine(base, modifiers, className)}
      type={type}
      onClick={handleClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
