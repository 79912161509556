export const combine = (...classNames) => {
  return classNames.join(" ");
};

export const getClassNameModifiers = (props, keys = []) => {
  return keys.map(name => getClassModifier(props, name)).join("");
};

const getClassModifier = (props, name) => {
  if (props[name]) {
    const dashCase = name.replace(/([A-Z])/g, function ($1) {
      return "-" + $1.toLowerCase();
    });

    let className = ` --${dashCase}`;

    if (typeof props[name] === "string") {
      className += `-${props[name]}`;
    }

    return className;
  }

  return "";
};
