import React, { useState } from "react";
import axios from "axios";

import { base, signupForm } from "./SubscribeForm.module.scss";

import Select from "../components/Select";
import Input from "../components/Input";

import { submitSubscribeForm } from "../utils/api";

const SubscribeForm = props => {
  const [success, setSuccess] = useState(null);

  const handleSubscribe = (evt, value) => {
    submitSubscribeForm(value)
      .then(() => {
        setSuccess(true);
        setTimeout(() => setSuccess(null), 6000);
      })
      .catch(() => {
        setSuccess(false);
        setTimeout(() => setSuccess(null), 4000);
      });
  };

  if (success === true) {
    return (
      <div className={base}>
        <h3>Thanks for joining!</h3>
        <p>Look out for regular emails with Brandcrush updates, features and industry insights.</p>
        <p>
          <small>You can change your preferences at any time.</small>
        </p>
      </div>
    );
  }

  if (success === false) {
    return (
      <div className={base}>
        <h3>Oops, something went wrong!</h3>
        <p>Looks like you might have entered an invalid email address</p>
      </div>
    );
  }

  return (
    <div className={base}>
      <h3>
        Sign up below to get the latest updates, product features and industry insights direct to
        your inbox.
      </h3>
      <form className={signupForm}>
        <Select
          name="userType"
          options={[
            { value: "partner", label: "I'm a media owner" },
            { value: "brand", label: "I'm a media buyer" },
            { value: "other", label: "Other" },
          ]}
          initialValue="partner"
        />
        <Input
          id="email-subscribe"
          name="email"
          placeholder="Email"
          hasSubmitButton
          buttonLabel="Subscribe"
          onSubmit={handleSubscribe}
          required
        />
      </form>
    </div>
  );
};

export default SubscribeForm;
