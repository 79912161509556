import React from "react";
import { Link as GatsbyLink } from "gatsby";

import { base } from "./Link.module.scss";

import { combine } from "../utils/classNames";

const Link = props => {
  const { href, onClick, children, external, target, className } = props;

  const renderContent = () => {
    if (external) {
      return (
        <a href={href} target={target || "_blank"}>
          {children}
        </a>
      );
    } else if (onClick) {
      return <a onClick={onClick}>{children}</a>;
    } else {
      return <GatsbyLink to={href}>{children}</GatsbyLink>;
    }
  };

  return <span className={combine(base, className)}>{renderContent()}</span>;
};

export default Link;
