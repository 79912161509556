import React from "react";
import { Helmet } from "react-helmet";

import { main } from "./Layout.module.scss";

import Header from "../components/Header";
import MobileHeader from "../components/MobileHeader";
import Footer from "../components/Footer";

import { combine, getClassNameModifiers } from "../utils/classNames";

const Layout = props => {
  const { children, noFooter, isFixed, pageTitle, ogTitle, ogDescription, ogImage } = props;

  const modifiers = getClassNameModifiers(props, ["isFixed"]);

  const pageUrl = typeof window !== "undefined" ? window.location.href : "";

  return (
    <div>
      <Helmet>
        <title>{pageTitle || "Brandcrush | Omnichannel Media Platform"}</title>
        <meta name="description" content={ogDescription || "An end-to-end platform for shopper marketing, retail media and b2b partnerships. Making it easier for businesses to buy and sell omnichannel media."} />
        <meta property="og:title" content={ogTitle || "Brandcrush: Retail Media, Activations and Partnerships Platform"} />
        <meta property="og:description" content={ogDescription || "An end-to-end platform for shopper marketing, retail media and b2b partnerships. Making it easier for businesses to buy and sell omnichannel media."} />
        <meta property="og:image" content={ogImage || ""} />
        <meta property="og:url" content={pageUrl} />
        <meta property="twitter:card" content="summary_large_image" />
        <link rel="shortcut icon" type="image/x-icon" href="/favicon.ico" />
      </Helmet>
      <Header className="desktop-only" isFixed={isFixed} />
      <MobileHeader className="mobile-only" />
      <main className={combine(main, modifiers)}>
       {children}
      </main>
      {!noFooter && <Footer />}
    </div>
  );
};

export default Layout;
