const axios = require("axios");

const FEATURED_TAG_ID = 50608218915;
const PARTNER_HIGHLIGHT_TAG_ID = 51132565933;
const BRAND_HIGHLIGHT_TAG_ID = 51132811014;
const RETAIL_GROCERY_HIGHTLIGHT_TAG_ID = 51284526502;
const D2C_RETAIL_FOOD_DELIVERY_HIGHLIGHT_TAG_ID = 51284738634;
const SHOPPING_CENTRES_MALLS_HIGHLIGHT_TAG_ID = 51284526517;
const HEALTH_WELLNESS_HIGHLIGHT_TAG_ID = 51284526519;
const CORPORATE_COWORKING_HIGHLIGHT_TAG_ID = 51284526595;
const UNIVERSITIES_HIGHLIGHT_TAG_ID = 51284526601;
const HOSPITALITY_TRAVEL_HIGHLIGHT_TAG_ID = 51284526610;

const BLOG_CONTENT_GROUP_ID = 6148065719;
const CASE_STUDY_CONTENT_GROUP_ID = 50217965228;

const CASE_STUDY_HEADER_MODULE = "module_1626671629983111";
const CASE_STUDY_BODY_MODULE = "module_1626677198242250";
const CASE_STUDY_MEDIA_MODULE = "module_1626677201604252";

const getAllBlogs = async (limit = 300, offset = 0) => {
  try {
    return await axios.get("https://api.hubapi.com/content/api/v2/blog-posts", {
      headers: {
        'Authorization': `Bearer ${process.env.HUBSPOT_ACCESS_TOKEN}`,
        'Content-Type': 'application/json'
    },
      params: {
        scope: "content",
        archived: false,
        order_by: "-updated",
        limit,
        offset,
        content_group_id: BLOG_CONTENT_GROUP_ID,
        state: "PUBLISHED",
      },
    });
  } catch (error) {
    console.log(error);
  }
};

const getAllCaseStudies = async (limit = 300, offset = 0) => {
  try {
    return await axios.get("https://api.hubapi.com/content/api/v2/blog-posts", {
      headers: {
        'Authorization': `Bearer ${process.env.HUBSPOT_ACCESS_TOKEN}`,
        'Content-Type': 'application/json'
    },
      params: {
        scope: "content",
        archived: false,
        order_by: "-updated",
        limit,
        offset,
        content_group_id: CASE_STUDY_CONTENT_GROUP_ID,
        state: "PUBLISHED",
      },
    });
  } catch (error) {
    console.log(error);
  }
};

const getPageContent = async url => {
  try {
    return await axios.get(url);
  } catch (error) {
    console.log(error);
  }
};

const uploadFile = (name, byteArray) => {
  const baseUrl =
    process.env.NODE_ENV === "production"
      ? "https://api.brandcrush.com"
      : "https://dev.api.brandcrush.io";

  return axios.post(baseUrl + "/hubspot/upload-file", {
    fileName: name,
    bytes: byteArray,
  });
};

const submitContactForm = values => {
  return axios.post(
    "https://api.hsforms.com/submissions/v3/integration/submit/4363226/3cbcacb7-8d3a-445e-a920-0abda002a4fc",
    {
      fields: [
        { name: "firstname", value: values.firstName },
        { name: "lastname", value: values.lastName },
        { name: "email", value: values.email },
        { name: "mobilephone", value: values.phone },
        { name: "stakeholder", value: values.userType },
        { name: "country_pulled_from_brandcrush", value: values.country },
        { name: "message", value: values.message },
      ],
    }
  );
};

const submitSupportForm = values => {
  return axios.post(
    "https://api.hsforms.com/submissions/v3/integration/submit/4363226/e5ea5fa8-854c-4071-90f9-535eb440ba7a",
    {
      fields: [
        { name: "firstname", value: values.firstName },
        { name: "email", value: values.email },
        { name: "TICKET.subject", value: values.subject },
        { name: "TICKET.content", value: values.description },
        { name: "TICKET.hs_ticket_category", value: values.category },
        { name: "TICKET.hs_file_upload", value: values.document },
      ],
    }
  );
};

const submitDownloadOverviewForm = values => {
  return axios.post(
    "https://api.hsforms.com/submissions/v3/integration/submit/4363226/f82c77db-c527-4898-aab9-8dcc59867121",
    {
      fields: [
        { name: "firstname", value: values.firstName },
        { name: "email", value: values.email },
        { name: "company", value: values.company },
        { name: "country_pulled_from_brandcrush", value: values.country },
      ],
    }
  );
};

const submitDemoForm = values => {
  return axios.post(
    "https://api.hsforms.com/submissions/v3/integration/submit/4363226/6977c01a-3ee4-4c8a-9677-0cbdd3af73f8",
    {
      fields: [
        { name: "firstname", value: values.firstName },
        { name: "lastname", value: values.lastName },
        { name: "email", value: values.email },
        { name: "mobilephone", value: values.phone },
        { name: "stakeholder", value: values.userType },
        { name: "country_pulled_from_brandcrush", value: values.country },
      ],
    }
  );
};

const submitSubscribeForm = email => {
  return axios.post(
    "https://api.hsforms.com/submissions/v3/integration/submit/4363226/b6acb0d6-24c1-4442-a7c5-7bfea7798f4a",
    {
      fields: [{ name: "email", value: email }],
    }
  );
};

const formatCaseStudy = (caseStudy, allCaseStudies) => {
  if (!caseStudy) {
    return;
  }

  const headerModule =
    caseStudy.widgets[CASE_STUDY_HEADER_MODULE] && caseStudy.widgets[CASE_STUDY_HEADER_MODULE].body;
  const mediaModule =
    caseStudy.widgets[CASE_STUDY_MEDIA_MODULE] && caseStudy.widgets[CASE_STUDY_MEDIA_MODULE].body;
  const bodyModule =
    caseStudy.widgets[CASE_STUDY_BODY_MODULE] && caseStudy.widgets[CASE_STUDY_BODY_MODULE].body;

  if (!headerModule || !mediaModule || !bodyModule) {
    return;
  }

  return {
    id: caseStudy.id,
    platformSide: headerModule.platform_side,
    partnerLogo: headerModule.partner_logo,
    partnerLogoAlt: headerModule.partnerLogoAlt,
    brandLogo: headerModule.brand_logo,
    brandLogoAlt: headerModule.brandLogoAlt,
    activationType: headerModule.activation_type,
    title: headerModule.title,
    subtitle: headerModule.subtitle,
    featured: {
      headline:
        headerModule.featured && headerModule.featured.featured_headline
          ? headerModule.featured.featured_headline
          : headerModule.title,
      extract:
        headerModule.featured && headerModule.featured.featured_extract
          ? headerModule.featured.featured_extract
          : bodyModule.content,
      author:
        headerModule.featured && headerModule.featured.featured_author
          ? headerModule.featured.featured_author
          : null,
      authorRole:
        headerModule.featured && headerModule.featured.featured_author_role
          ? headerModule.featured.featured_author_role
          : null,
    },
    statistics:
      headerModule.statistics &&
      headerModule.statistics.map(statistic => ({
        value: statistic.statistic_value,
        label: statistic.statistic_label,
      })),
    featureImages:
      mediaModule.feature_image &&
      mediaModule.feature_image.map(image => ({
        src: image.src,
        alt: image.atl,
      })),
    featureVideo: mediaModule.feature_video_url && {
      src: mediaModule.feature_video_url,
    },
    quotes:
      mediaModule.quote &&
      mediaModule.quote.map(quote => ({
        quote: quote.quote,
        author: quote.quote_author,
      })),
    content: bodyModule.content,
    slug: caseStudy.slug,
    relatedCaseStudies:
      headerModule.related_case_studies &&
      allCaseStudies &&
      allCaseStudies.length > 0 &&
      allCaseStudies
        .filter(
          caseStudy =>
            headerModule.related_case_studies.map(cs => cs.content_id).indexOf(caseStudy.id) >= 0
        )
        // Note calling formatCaseStudy here without the second argument prevents infinite recursion
        .slice(0, 3)
        .map(caseStudy => formatCaseStudy(caseStudy)),
  };
};

const hasTag = (data, tagId) => data.tag_ids.indexOf(tagId) >= 0;

const isFeatured = data => hasTag(data, FEATURED_TAG_ID);
const isPartnerHighlight = data => hasTag(data, PARTNER_HIGHLIGHT_TAG_ID);
const isBrandHighlight = data => hasTag(data, BRAND_HIGHLIGHT_TAG_ID);
const isRetailGroceryHighlight = data => hasTag(data, RETAIL_GROCERY_HIGHTLIGHT_TAG_ID);
const isD2CRetailFoodDeliveryHighlight = data =>
  hasTag(data, D2C_RETAIL_FOOD_DELIVERY_HIGHLIGHT_TAG_ID);
const isShoppingCentresMallsHighlight = data =>
  hasTag(data, SHOPPING_CENTRES_MALLS_HIGHLIGHT_TAG_ID);
const isHealthWellnessHighlight = data => hasTag(data, HEALTH_WELLNESS_HIGHLIGHT_TAG_ID);
const isCorporateCoworkingHighlight = data => hasTag(data, CORPORATE_COWORKING_HIGHLIGHT_TAG_ID);
const isUniversitiesHighlight = data => hasTag(data, UNIVERSITIES_HIGHLIGHT_TAG_ID);
const isHospitalityTravelHighlight = data => hasTag(data, HOSPITALITY_TRAVEL_HIGHLIGHT_TAG_ID);

const isBrand = data =>
  data.widgets[CASE_STUDY_HEADER_MODULE] &&
  (data.widgets[CASE_STUDY_HEADER_MODULE].body.platform_side === "brand" ||
    data.widgets[CASE_STUDY_HEADER_MODULE].body.platform_side === "brand_partner");

// platform_side defaults to 'partner' in HS, so if it is absent, we assume it's 'partner'
const isPartner = data =>
  data.widgets[CASE_STUDY_HEADER_MODULE] &&
  (!data.widgets[CASE_STUDY_HEADER_MODULE].body.platform_side ||
    data.widgets[CASE_STUDY_HEADER_MODULE].body.platform_side === "partner" ||
    data.widgets[CASE_STUDY_HEADER_MODULE].body.platform_side === "brand_partner");

const toActivationType = data =>
  data.widgets[CASE_STUDY_HEADER_MODULE] &&
  data.widgets[CASE_STUDY_HEADER_MODULE].body.activation_type;

module.exports = {
  getAllBlogs,
  getAllCaseStudies,
  uploadFile,
  submitContactForm,
  submitSupportForm,
  submitDemoForm,
  submitSubscribeForm,
  submitDownloadOverviewForm,
  isFeatured,
  isPartnerHighlight,
  isBrandHighlight,
  isRetailGroceryHighlight,
  isD2CRetailFoodDeliveryHighlight,
  isShoppingCentresMallsHighlight,
  isHealthWellnessHighlight,
  isCorporateCoworkingHighlight,
  isUniversitiesHighlight,
  isHospitalityTravelHighlight,
  isBrand,
  isPartner,
  formatCaseStudy,
  toActivationType,
};
